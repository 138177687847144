<template>
  <div class="container">
    <div class="banner">
        <div class="w solution-bg">
            <h3>企业品牌开发解决方案</h3>
            <p>品牌设计是在企业自身正确定位的基础之上，基于正确品牌定义下的视觉沟通<br />它是一个协助企业发展的形象实体，不仅协助企业正确的把握品牌方向<br />而且能够使人们正确的、快速的对企业形象进行有效深刻的记忆</p>
            <button @click="showKefuBox">在线咨询&nbsp;<i class="icon-im"></i></button>
        </div>
    </div>
    <!--//面临的问题与挑战-->
    <div class="solution-question">
        <div class="w">
            <h3 class="t">品牌设计服务范围</h3>
            <ul>
                <li><span><i class="icon iconfont">&#xe606;</i></span><h3>LOGO设计</h3><p>提升企业的品牌与产品价值</p></li>
                <li><span><i class="icon iconfont">&#xe6e6;</i></span><h3>VI设计</h3><p>企业视觉识别系统</p></li>
                <li><span><i class="icon iconfont">&#xe6eb;</i></span><h3>宣传品设计</h3><p>每次出品彰显高端品质</p></li>
                <li><span><i class="icon iconfont">&#xe760;</i></span><h3>广告设计</h3><p>源自于自然无穷的创意体现</p></li>
                <li><span><i class="icon iconfont">&#xe87f;</i></span><h3>包装设计</h3><p>设计价值,初次见面如获至宝</p></li>
            </ul>
        </div>
    </div>
    <!--//解决方案内容-->
    <div class="solution-content">
        <div class="w">
            <div class="desc"><strong>多年年来只专注品牌策划设计这一件事</strong><br />致力于为注重品牌策略和品牌形象的高要求客户提供品牌战略营销咨询、品牌策划设计、产品策划包装设计、品牌传播推广等服务。<br />
由业内知名总监合力智建，靠专业和口碑吸引众多客户，多项作品获德国红点奖、IF、意大利A'Design、台湾金点奖、香港HKDA环球设计等大奖。
<br />我们不断学习，成长。成员平均十年以上项目操作经验，已是中国设计界一股不可或缺的力量。</div>
            <div class="tab">
                <span class="selected">品牌策划</span>
                <span>品设定位</span>
                <span>设计定位</span>
                <span>设计输出</span>
                <span>高效验收</span>
            </div>
            <div class="tab-content">
                <div class="tabc tabshow">
                    <h4>企业文化调研</h4>
                    <p>专业品牌策划营销团队，针对企业文化、产品、市场定位、同类企业、同类产品、营销重点等18项丰富资料收集清洗，还原企业文化本身的原始特点和特色输出</p>
                    <h4>筛选关键词</h4>
                    <p>通过对调研内容进行二次、三次筛选，定位相关市场、企业、人文、环境等元素关键词，为设计输出提供特色突出点内容</p>
                    <h4>定位配色、结构形态</h4>
                    <p>确定整个设计在内容识别色彩、空间三位一体的设计理念结构，为后续更好的把控设计需求提供依据</p>
                </div>
                <div class="tabc">
                    <h4>品牌核心点</h4>
                    <p>了解产业环境，确认自己的强弱点，决定“核心”生意</p>
                    <h4>品牌价值观</h4>
                    <p>形成企业的长远开展目标及可操作的价值观</p>
                    <h4>建立完整的企业识别，并形成维护管理体系</h4>
                    <p>确认品牌与消费者的关系，进行品牌定位</p>
                    <h4>确定品牌策略及品牌识别</h4>
                    <p>明确品牌责任归属，建立品牌机构，组织运作品牌</p>
                    <h4>营销信息有效</h4>
                    <p>整合营销传播计划及执行，保证品牌与消费者的每一个接触点都能传达有效的信息</p>
                    <h4>品牌跟踪与诊断</h4>
                    <p>直接接触消费者，持续记录，建立品牌档案，不断培养消费者的品牌忠诚度</p>
                    <h4>品牌评估</h4>
                    <p>建立评估系统，跟踪品牌资产</p>
                </div>
                <div class="tabc">
                    <h4>色彩定位</h4>
                    <p>制定品牌设计色彩搭配定位内容</p>
                    <h4>三维形态结构定位</h4>
                    <p>制定品牌设计三维形态结构内容</p>
                    <h4>元素定位</h4>
                    <p>为设计内容制定突出市场、企业、营销等内容表现形态元素方案定位</p>
                    <h4>人群、喜好、个性定位</h4>
                    <p>面向市场不同类型人群等用户终端定位</p>
                    <h4>渠道方向定位</h4>
                    <p>为品牌输出面向渠道使用方向进行定位，确定产品的时效和优质高效使用范围</p>
                </div>
                <div class="tabc">
                     <h4>样品</h4>
                    <p>针对具体设计需求和方案提供可筛选的1-3个高品质样品，为需求具体程序结果设计</p>
                    <h4>交付品</h4>
                    <p>在符合要求的样品中筛选和复盘设计，为更好和丰富的把握设计成品质量和输出</p>
                    <h4>验收品</h4>
                    <p>提供有效可投放市场的交付品，为企业正常运行试用、准成品方案，配合后续成果检测！！是否满足设计实际需求！</p>
                </div>
                <div class="tabc">
                    <h4>项目的起-止管控</h4>
                    <p>项目的开始和结束我们都是认真的，只有高效、高品质的行径每一步，结果风险就越低，整个过程形成CMMIC完整管控，不让任何风险留在后续操作中</p>
                    <h4>透明化</h4>
                    <p>整个项目操作过程实现完全透明化，对设计人员，对客户，对供应商都通过统一平台进行实时、有效、可追溯、可沟通等透明化处理，让任何问题处理均快速有效，不留任何疑问</p>
                    <h4>交付跟踪-长效机制</h4>
                    <p>对每一项作品企业均可在交付后和交付前实行终身服务</p>
                    <h4>增值、归属权交付</h4>
                    <p>品牌建设过程中交付的产品全类品实现企业增值服务，让产品有效转换企业资产</p>
                </div>
            </div>
        </div>
    </div>
    <!--//定制开发技术标准-->
    <div class="solution-customization">
        <div class="w">
            <h3 class="t">企业为什么需要品牌设计</h3>
            <div class="d">品牌管理的目的在于通过细分市场找到自己的独特性，建立自己的品牌优势，并获取利润。品牌能够在市场上脱颖而出，企业必须更新观念，避免只重媒体宣传、促销等短期行为，而要注重品牌的延伸性管理。品牌办理是对树立、维护、巩固品牌的全过程，是一个有效监管控制及其与消费者之间的联系的全方位管理过程，只有通过品牌管理才能实现品牌远景，最终确立品牌的竞争优势</div>
            <p><br /><strong>第一、企业之间的竞争是品牌的较量，而品牌的较量首先就要进行品牌设计。</strong><br /><br />企业要在市场竞争中长时间地名列前茅或具有一席之地，培育自己有效品牌是仅有的选择，而要进行品牌规划，就有必要对品牌进行定位，在定位时就需要进行品牌形象设计。通过各种形象符号来影响潜在消费者，在消费者心智模式中建立企业鲜明的企业形象，将其品牌信息与方针消费者达成心理共识，通过长时间的宣扬，在耳濡目染中逐步将企业的强势品牌概念深入人心，然后带动产品销售。<br />
<strong>第二、品牌设计能减少消费群体的流失并稳定市场。</strong>
<br /><br />品牌设计是为了将企业本身的产品和市场上同类竞品区隔开，通过各种方式的长时间宣扬，让消费者在耳濡目染中逐步构成企业独特的强势品牌，然后可减少消费者选择产品时可能会失去的消费群体。
<br /><br /><strong>第三、品牌设计是企业进行品牌延伸的前提。</strong>
<br /><br />品牌延伸可以减少新产品导入市场的风险和成本。但要进行品牌延伸，被延伸的品牌有必要是有价值的、消费者熟知和信任的品牌。
<br /><br />以上也就是品牌设计的步骤和意义，这也就是为什么现在大众这么相信品牌力量的原因，要想做好企业的品牌，还需要花费很大的功夫，不过既然想要发展企业，那就要建立一个良好的品牌，才会得到更多用户的信任，才能在激烈的市场中立足于不败之地</p>
        </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
    mounted: function() {
        $(function(){
            $(".tab span").hover(function(){
                $(".tab span.selected").removeClass("selected");
                $(this).addClass("selected");
                $('.tab-content .tabshow').removeClass("tabshow");
                $('.tab-content .tabc').eq($(this).index()).addClass("tabshow");
            });
        });
    },
    methods: {
        showKefuBox: function()
        {
            this.$store.commit("setKefuStatus",true);
        }
    }
}
</script>

<style>
.banner h3{ font-size: 36px; color: #ffffff; font-weight: normal; padding-top: 60px; margin:0px;}
.banner p{ font-size: 18px; color: #ffffff; line-height: 30px;}
.banner button{ background-color: #ffffff; border: 1px #ffffff solid; border-radius: 3px; padding: 10px 40px; font-size: 18px; cursor: pointer; margin-top: 30px;}
.banner button i{ font-size: 30px; }
.solution-bg{ background: url("../assets/so_develop_f.png") right bottom no-repeat; height: 90%;}

.solution-question h3.t{ font-size: 25px; font-weight: normal; margin-top: 50px;}
.solution-question ul,.solution-question ul li,.solution-question ul li h3{ margin: 0px; padding: 0px; list-style: none; display: block; line-height: 30px;}
.solution-question ul{  border-right: 1px #eeeeee solid; height: 230px; overflow: hidden; margin: 30px 0px;}
.solution-question ul li{ width: 19%; overflow: hidden; float: left; text-align: center; height: 230px; padding-top: 40px; border-left: 1px #eeeeee solid; cursor: pointer; color: #000000;}
.solution-question ul li span i{ font-size: 45px;}
.solution-question ul li:hover{ color: #004172;}
.solution-question ul li h3{ font-size: 18px;}
.solution-question ul li p{ font-size: 14px;}

.solution-content{ background-color: #edecec; padding: 60px 0px; border-top: 1px #dddddd solid; line-height: 2.0;}
.solution-content .desc{ font-size: 16px; line-height:2.0;}
.solution-content .tab{ text-align: center; margin: 30px 0px;}
.solution-content .tab span{ font-size: 18px; padding: 10px 25px; cursor: pointer;}
.solution-content .tab span:hover,.solution-content .tab span.selected{ background-color: #ffffff; border-radius: 5px;}
.solution-content .tab-content{ background-color: #ffffff; border-radius: 5px; padding: 50px;}
.solution-content .tab-content .tabc{ width: 90%; display: none;}
.solution-content .tab-content .tabshow{ display: block;}
.solution-content .tab-content .tabc h4{ font-size: 18px; font-weight: normal;}
.solution-content .tab-content .tabc p{ font-size: 14px; line-height: 40px;}

.solution-customization{ margin-bottom: 50px;}
.solution-customization h3.t{ font-size: 25px; font-weight: normal; margin-top: 50px;}
.solution-customization p{ font-size: 16px; line-height: 2.0;}
.solution-customization .d{ font-size: 18px; line-height: 24px;}
.solution-customization .f{ margin: 30px 0px;}
.solution-customization .f img{ float: left;}
.solution-customization .g{ float:right; width: 60%;}
.solution-customization .g div{ margin-bottom: 10px; clear: both;}
.solution-customization .g span{ font-size: 14px; line-height: 30px; background-color: #eeeeee; border-radius: 5px; padding: 10px;}
.solution-customization .g span.l{ display: block;}
</style>
